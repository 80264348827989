<template lang="pug">
  .template-modal
    TemplatesTable(
      :templates="templates"
      @delete="onDeleteTemplate"
    )
    AppPagination(
      :total="pagination.total_count"
      :per-page="pagination.per_page"
      :current-page="pagination.current_page"
      @change-pagination-data="onChangePagination"
    )
</template>

<script>
  // store modules
  import basicSettingsModule from "@/config/store/price_management/plan_settings/basic_settings"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"

  const basicSettingsPaginationMixin = withStoreModule(basicSettingsModule, {
    name: "paginatedTemplates",
    readers: ["pagination", "filters", "templates"],
    mutations: {
      setPagination: "SET_PAGINATION_DATA"
    },
    actions: {
      fetchPaginatedTemplates: "FETCH_TEMPLATES",
      deleteTemplate: "DELETE_TEMPLATE"
    }
  })

  const basicSettingsMixin = withStoreModule(basicSettingsModule, {
    name: "basicSettings",
    readers: ["template"],
    actions: {
      fetchAllTemplates: "FETCH_TEMPLATES"
    },
    mutations: {
      setTemplate: "SET_TEMPLATE"
    }
  })

  export default {
    mixins: [basicSettingsPaginationMixin, basicSettingsMixin],

    mounted() {
      this.fetchPaginatedTemplates()
    },

    components: {
      TemplatesTable: () => import("./TemplatesTable"),
      AppPagination: () => import("@/components/elements/AppPagination")
    },

    methods: {
      onChangePagination(paginationData) {
        this.setPagination({ ...this.pagination, ...paginationData })
        this.fetchPaginatedTemplates()
      },

      async onDeleteTemplate(id) {
        // To avoid the conflicts after deleting of already selected template, need to set empty
        if (this.template.id === id) {
          this.setTemplate({})
        }

        await this.deleteTemplate(id)
        this.fetchAllTemplates()
      }
    }
  }
</script>
