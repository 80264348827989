<template lang="pug">
  .ota-header
    .alert-wrapper
      BAlert(
        v-for="message in messages"
        :key="message.title"
        show
        dismissible
        :variant="message.type"
      )
        span {{ message.title }}
    .actions
      .template-wrapper
        span.label {{ $t("plan_settings.basic_settings.templates.use_template") }}
        .template-select
          AppDropdown.cy-template-name(
            close-on-select
            title-key="template_name"
            :placeholder="$t('notifications.filters.nothing_selected')"
            :items="templates"
            :value="template"
            @select="handleSelectTemplate"
          )
        AppIconButton.list-icon(
          icon="list-alt"
          @click="openTemplatesModal"
        )
</template>

<script>
  // store modules
  import basicSettingsModule from "@/config/store/price_management/plan_settings/basic_settings"

  // mixins
  import withModal from "@/mixins/withModal"
  import withStoreModule from "@/mixins/withStoreModule"
  import withConfirmation from "@/mixins/withConfirmation"

  // misc
  import { getPlanFromTemplate } from "@/config/store/price_management/plan_settings/basic_settings/helpers"
  import { isEmpty } from "lodash-es"
  import Modal from "./Modal"

  const basicSettingsMixin = withStoreModule(basicSettingsModule, {
    name: "basicSettings",
    readers: ["templates", "template", "messages", "isDirty"],
    mutations: { setTemplate: "SET_TEMPLATE" }
  })

  export default {
    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    props: {
      validator: Object
    },

    mixins: [withModal, basicSettingsMixin, withConfirmation],

    methods: {
      isEmpty,

      openTemplatesModal() {
        this.$openModal({
          title: this.$t("plan_settings.basic_settings.templates.modal_title"),
          component: Modal,
          size: "large",
          closeOnEsc: true,
          closeOnClick: true
        })
      },

      handleSelectTemplate(template) {
        this.$conditionalConfirm({
          useConfirm: this.isDirty,
          handler: () => {
            this.validator.$model = getPlanFromTemplate(this.validator.$model, template)
            this.setTemplate(template)
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .ota-header
    .actions
      display: flex
      align-items: center

      .template-wrapper
        width: 100%
        display: flex
        justify-content: flex-end
        gap: 0.5rem
        align-items: center
        margin-bottom: 5px

        .label
          font-weight: normal
          font-size: 0.8rem
          color: $default-gray

        .template-select
          min-width: 200px

        .list-icon
          +icon-button($default-purple)
</style>
